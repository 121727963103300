import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/2MxzaOzZLZ8"
    bibleGroupSrc="https://pdfhost.io/v/Q~FfeydbN_Bible_Group_Homework_124pdf.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="From Passive to Active - If Not Us, Who?" />
  </Layout>
)

export default SermonPost
